import { useState } from 'react';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PrimaryButton } from './misc/Buttons';
import tw from "twin.macro";
import { SectionDescription } from './misc/Typography';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const VerticalSpacer = tw.div`mt-10 w-full`

export default function Checkout() {

    const options = {
        mode: 'subscription',
        amount: 0,
        currency: 'usd',
        setup_future_usage: 'off_session',
        appearance: {
            theme: 'flat',
            variables: { colorPrimaryText: '#262626' }
        },
    };

    return <Elements stripe={stripePromise} options={options}>
        <CheckoutInner />
    </Elements>
}

function CheckoutInner() {
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(false);

    const handleError = (error) => {
        setLoading(false);
        setErrorMessage(error.message);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) { return; }

        setLoading(true);
        setErrorMessage(undefined);

        try {
            const res = await fetch('/.netlify/functions/create-subscription', { method: "POST", headers: { "ngrok-skip-browser-warning": true } });
            console.log(JSON.stringify(res));
            const { clientSecret } = await res.json();

            // Trigger form validation and wallet collection
            const { error: submitError } = await elements.submit();
            if (submitError) {
                handleError(submitError);
                return;
            }

            // Send payment details to Stripe
            const result = await stripe.confirmSetup({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: process.env.REACT_APP_BASE_URL,
                },
            });

            if (result.error) {
                handleError(result.error);
            }
        }
        catch (error) {
            handleError(error);
        };
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <VerticalSpacer />
            <PrimaryButton disabled={!stripe || loading}>Submit</PrimaryButton>
            <SectionDescription>{errorMessage ? `Error: ${errorMessage}` : "Your card won't be charged until the free trial ends."}</SectionDescription>
        </form>
    );
};