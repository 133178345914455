// import { css } from "styled-components/macro"; //eslint-disable-line
import { ClerkProvider } from "@clerk/clerk-react";
import { unstable_createTheme } from '@clerk/themes';
import LandingPage from "LandingPage.js";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import GlobalStyles from 'styles/GlobalStyles';

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;
  const theme = unstable_createTheme({
    variables: {
      colorPrimary: '#3a7926',
      colorBackground: '#d5f2c8',
      colorInputBackground: '#aae897',
      colorAlphaShade: 'black',
      colorText: 'black',
      colorInputText: 'black',
      colorShimmer: 'rgba(255,255,255,0.36)',
      shadowShimmer: '1px 1px 2px rgba(0,0,0,0.36)',
    }
  });

  return (
    <ClerkProvider publishableKey={clerkPubKey} appearance={{ baseTheme: theme }}>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </Router>
    </ClerkProvider>
  );
}
