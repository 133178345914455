import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import colors from 'tailwindcss/colors'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt, faEnvelopeOpenText, faHandshakeSimple } from '@fortawesome/free-solid-svg-icons'

const Container = tw.div`relative bg-primary-900 -mx-8 px-8 text-gray-900`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-900`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-700`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-primary-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`tracking-wider font-medium text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-700 leading-snug`}
  }
`;

export default ({
  cards = null,
  heading = "Watch your savings thrive",
  subheading = "",
  description = "Discover the effortless way to grow your savings. Our subscription service ensures you never miss out on higher interest rates available, saving you time and helping you earn more on your hard-earned money."
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      icon: faBolt,
      title: "Daily Monitoring",
      description: "We diligently monitor APY changes daily, ensuring you never miss out on better opportunities."
    },
    {
      icon: faEnvelopeOpenText,
      title: "Email Alerts",
      description: "Stay informed and proactive with email alerts for higher-APY accounts."
    },
    {
      icon: faHandshakeSimple,
      title: "Flexible Control",
      description: "Easily pause or cancel your subscription at any time, giving you full control and peace of mind."
    }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container id="features">
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <FontAwesomeIcon icon={card.icon} color={colors.white} size="xl" />
                {/* <img src={card.imageSrc || defaultCardImage} alt="" /> */}
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
