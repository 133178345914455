import { SignInButton, SignUpButton, SignedIn, SignedOut, UserButton } from "@clerk/clerk-react";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { SectionHeading } from "components/misc/Headings.js";
import { Container as ContainerBase, Content2Xl, ContentWithVerticalPadding } from "components/misc/Layouts.js";
import { SectionDescription } from "components/misc/Typography.js";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import tw from "twin.macro";
import HeaderBase, {
  LogoLink as LogoLinkBase,
  NavLink as NavLinkBase,
  NavLinks,
  NavAnchorLink,
  PrimaryLink as PrimaryLinkBase
} from "./headers/light.js";

import { Suspense, lazy } from "react";

const MoneyImage = lazy(() => import("./images/MoneyImage.js"));
const LogoImage = lazy(() => import("./images/LogoImage.js"));

const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 bg-primary-900 text-gray-900`;
const Header = tw(HeaderBase)`max-w-none -mt-8 py-8 -mx-8 px-8`;
const NavLink = tw(NavLinkBase)`lg:text-gray-900 lg:hocus:text-gray-700 lg:hocus:border-gray-900`;
const LogoLink = tw(LogoLinkBase)`text-gray-900 hocus:text-gray-700 pr-4`;
const PrimaryLink = tw(PrimaryLinkBase)`cursor-pointer shadow-raised lg:bg-primary-400 lg:hocus:bg-primary-500`;

const Container = tw(ContainerBase)``;
const Row = tw.div`flex items-center flex-col lg:flex-row`;
const Column = tw.div`lg:w-1/2`;
const TextColumn = tw.div`text-center lg:text-left`;
const IllustrationColumn = tw(Column)`mt-16 lg:mt-0 lg:ml-16`;
const Heading = tw(SectionHeading)`max-w-3xl lg:max-w-4xl lg:text-left leading-tight`;
const Description = tw(SectionDescription)`mt-4 max-w-2xl text-gray-900 lg:text-base mx-auto lg:mx-0`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 text-sm sm:text-base px-6 py-5 sm:px-10 sm:py-5 bg-primary-400 inline-block hocus:bg-primary-500`;

export default ({
  heading = "Never miss a better APY again",
  description = "Boost your savings with our exclusive service. We'll scour Raisin (formerly SaveBetter)'s network of banks and credit unions, and notify you when higher interest rates become available. Then simply login to Raisin and move your funds to the higher-APY account.",
  primaryButtonText = "Start Your 60-Day Free Trial",
}) => {
  const logoLink = (
    <LogoLink href="/">
      <Suspense><LogoImage /></Suspense>
      <h1>Yield Alarm <em><small> &nbsp;for Raisin</small></em></h1>
    </LogoLink>
  );
  const navLinks = [
    <NavLinks key={1}>
      <SignedOut>
        <NavAnchorLink><AnchorLink href="#features">Features</AnchorLink></NavAnchorLink>
        <NavAnchorLink><AnchorLink href="#pricing">Pricing</AnchorLink></NavAnchorLink>
        <NavAnchorLink><AnchorLink href="#faq">FAQ</AnchorLink></NavAnchorLink>
        <SignInButton redirectUrl={process.env.REACT_APP_BASE_URL} mode="modal"><NavLink href="#">Login</NavLink></SignInButton>
        <SignUpButton redirectUrl={process.env.REACT_APP_BASE_URL} mode="modal"><PrimaryLink>Start Free Trial</PrimaryLink></SignUpButton>
      </SignedOut>
      <SignedIn>
        <UserButton afterSignOutUrl={process.env.REACT_APP_BASE_URL} userProfileMode="modal" showName />
      </SignedIn>
    </NavLinks>
  ];
  return (
    <PrimaryBackgroundContainer>
      <Content2Xl>
        <Header logoLink={logoLink} links={navLinks} />
        <SignedOut>
          <Container>
            <ContentWithVerticalPadding>
              <Row>
                <TextColumn>
                  <Heading>{heading}</Heading>
                  <Description>{description}</Description>
                  <SignUpButton mode="modal">
                    <PrimaryButton as="a">{primaryButtonText}</PrimaryButton>
                  </SignUpButton>
                </TextColumn>
                <IllustrationColumn>
                  <Suspense><MoneyImage /></Suspense>
                </IllustrationColumn>
              </Row>
            </ContentWithVerticalPadding>
          </Container>
        </SignedOut>
      </Content2Xl>
    </PrimaryBackgroundContainer>
  );
};
